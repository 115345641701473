<template>
  <div class="container">
    <h2>Contact us</h2>
    <v-card
      class="mx-auto"
      elevation="1"
    >
      <v-img
        src="../assets/logo_waste.png"
        max-height="460px"
        max-width="460px"
        class="mx-auto"
      />
      <v-card-text>
        <h3>
          ພວກເຮົາຄືໜ່ວຍງານບໍລິການຂົນສົ່ງ ແລະ ເກັບມ້ຽນຂີ້ເຫື້ຍອ ເຊົ່ງເປັນໂຄງການຮ່ວມມືລະຫ່ວາງຫ້ອງການຄຸ້ມຄອງ ແລະ ບໍລິການ
          ຕົວເມືອງວຽງຈັນ ແລະ ສະມອນບີ້
        </h3>

        <v-list two-line>
          <v-list-item>
            <v-list-item-icon>
              <v-icon color="indigo">
                mdi-map-marker
              </v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Phonthan, Xaysettha, Vientiane Capital</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider inset />

          <v-list-item>
            <v-list-item-icon>
              <v-icon color="indigo">
                mdi-phone
              </v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>(856) 020 226222</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-divider inset />

          <v-list-item>
            <v-list-item-icon>
              <v-icon color="indigo">
                mdi-email
              </v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>sales@vientianewaste-codev.com</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>
